import React, { useState, useRef, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPlanById, postUpSertPlan } from "../../../../../core/api/Api";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import ButtonWithLoading from "../../../../_components/button-with-loading/ButtonWithLoading";
import LookupSelectView from "../../../../_components/input-multi-select/lookup-select-view/LookupSelectView";
import {
  getEmptyTranslationArr,
  parseNameOfTranslationInput,
} from "../../../../../core/common-service/CommonService";

const PlanDetails = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const mounted = useRef(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name?.startsWith("translations")) {
      const translation_obj = parseNameOfTranslationInput(name);
      var tmp_data = { ...data };
      tmp_data.translations[translation_obj.index][translation_obj.name] =
        value;
      setData(tmp_data);
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const onInputSelectChange =
    (foreignKey, multiple, d) => (e, item, reason) => {
      e.preventDefault();
      if (multiple) {
        var val = item.map((a) => a.id);
        if (val?.[0] === 0) {
          val = data?.lookups?.[d].map((a) => a.id);
        }
        setData({ ...data, [foreignKey]: val });
      } else {
        setData({ ...data, [foreignKey]: item?.id });
      }
    };

  const goToPage = (path) => (e) => {
    e.preventDefault();
    navigate(path);
  };

  const getDataById = () => {
    getPlanById(id)
      .then((res) => {
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          if (isNaN(id)) {
            data.translations = getEmptyTranslationArr([
              { key: "name", value: "" },
              { key: "description", value: "" },
              { key: "payed_per", value: "" },
              { key: "price_description", value: "" },
            ]);
            console.log(data);
          }
          setData(data);
        }
      })
      .catch((e) => {});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var d = { ...data, action: "upsert" };
    postUpSertPlan(d)
      .then((res) => {
        setLoading(false);
        const response = res?.data?.success;
        if (response) {
          if (isNaN(id)) {
            navigate("/plan-options/" + response?.id);
          }
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    mounted.current = true;
    getDataById();
    return () => {
      mounted.current = false;
    };
  }, [id]);

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      {(data?.id || isNaN(id)) && (
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            {"Plan Details"}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card component="form" noValidate onSubmit={onSubmit}>
                <CardHeader
                  subheader={"The informations can be edited"}
                  title={"Details"}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    {data?.lookups && (
                      <Fragment>
                        <LookupSelectView
                          onInputSelectChange={onInputSelectChange}
                          data={data}
                          d="services"
                          foreignKey="service_id"
                          l={"Services"}
                        />
                        <LookupSelectView
                          onInputSelectChange={onInputSelectChange}
                          data={data}
                          d="category_length_types"
                          foreignKey="category_length_type_id"
                          l={"Category Length Types"}
                        />
                      </Fragment>
                    )}

                    {data?.translations?.map((item, index) => (
                      <Fragment key={"language-item-" + index}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label={"Name (" + item.locale + ")"}
                            name={"translations-" + index + "-name"}
                            onChange={handleChange}
                            required
                            value={data?.translations?.[index]?.name || ""}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label={"Description (" + item.locale + ")"}
                            name={"translations-" + index + "-description"}
                            onChange={handleChange}
                            value={
                              data?.translations?.[index]?.description || ""
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label={"Payer Per (" + item.locale + ")"}
                            name={"translations-" + index + "-payer_per"}
                            onChange={handleChange}
                            value={data?.translations?.[index]?.payer_per || ""}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label={"Price Description (" + item.locale + ")"}
                            name={
                              "translations-" + index + "-price_description"
                            }
                            onChange={handleChange}
                            value={
                              data?.translations?.[index]?.price_description ||
                              ""
                            }
                            variant="outlined"
                          />
                        </Grid>
                      </Fragment>
                    ))}
                    <LookupSelectView
                      onInputSelectChange={onInputSelectChange}
                      data={data}
                      d="currencies"
                      foreignKey="currency_id"
                      l={"Currencies"}
                    />
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        label={"Price"}
                        type="number"
                        name="price"
                        onChange={handleChange}
                        value={data?.price || 0}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      />
                    </Grid>
                    {!isNaN(id) && (
                      <LookupSelectView
                        onInputSelectChange={onInputSelectChange}
                        data={data}
                        d="is_active_lookup"
                        foreignKey="is_gift_card"
                        l={"Is Gift Card"}
                        disabled={isNaN(id)}
                      />
                    )}
                    {!isNaN(id) && (
                      <LookupSelectView
                        onInputSelectChange={onInputSelectChange}
                        data={data}
                        d="is_active_lookup"
                        foreignKey="is_active"
                        l={"Is Active"}
                        disabled={isNaN(id)}
                      />
                    )}
                  </Grid>
                </CardContent>
                <Divider />
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                  {!isNaN(id) && (
                    <Button
                      onClick={goToPage("/plan-options/" + id)}
                      color="secondary"
                      variant="outlined"
                      sx={{
                        marginRight: "10px",
                      }}
                    >
                      {"View Plan Options"}
                    </Button>
                  )}
                  <ButtonWithLoading
                    loading={loading}
                    success={success}
                    text={"Save"}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default PlanDetails;
