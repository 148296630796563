import React from "react";
import { Grid } from "@mui/material";
import InputMultiSelect from "../InputMultiSelect";

const LookupSelectView = ({
  onInputSelectChange,
  data,
  d,
  foreignKey,
  l,
  multiple = false,
  required = false,
  disabled = false,
}) => {
  const isActiveArr = [
    { id: 0, name: "No" },
    { id: 1, name: "Yes" },
  ];
  var activeEl = null;
  if (multiple) {
    const arr = data?.[foreignKey];
    activeEl = data?.lookups?.[d]?.filter((f) =>
      arr?.some((item) => Number(item) === Number(f?.id))
    );
    // activeEl = data?.lookups?.[d]?.filter(el => el.id == data?.[foreignKey])
  } else if (foreignKey === "is_active" || foreignKey === "is_gift_card") {
    activeEl = isActiveArr?.find(
      (el) => Boolean(el.id) === Boolean(data?.[foreignKey])
    );
  } else {
    activeEl = data?.lookups?.[d]?.find(
      (el) => Number(el.id) === Number(data?.[foreignKey])
    );
  }
  return (
    <Grid item md={6} xs={12}>
      <InputMultiSelect
        activeEl={activeEl}
        label={l}
        disabled={disabled}
        multiple={multiple}
        required={required}
        onChange={onInputSelectChange(foreignKey, multiple, d)}
        options={
          (d === "is_active_lookup" ? isActiveArr : data?.lookups?.[d] || []) ||
          []
        }
        value={activeEl}
        getOptionLabel={(option) =>
          d === "category_length_types" || d === "category_based_types"
            ? option?.name + " (" + option?.general_category?.name + ")"
            : option?.name
        }
      />
    </Grid>
  );
};

export default LookupSelectView;
