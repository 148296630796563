import React, { useState, useRef, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCouponById, postUpSertCoupon } from "../../../../core/api/Api";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ButtonWithLoading from "../../../_components/button-with-loading/ButtonWithLoading";
import LookupSelectView from "../../../_components/input-multi-select/lookup-select-view/LookupSelectView";
import {
  getEmptyTranslationArr,
  parseNameOfTranslationInput,
} from "../../../../core/common-service/CommonService";
import dayjs from "dayjs";

const CouponDetails = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const mounted = useRef(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isUnlimitedTime, setIsUnlimitedTime] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name?.startsWith("translations")) {
      const translation_obj = parseNameOfTranslationInput(name);
      var tmp_data = { ...data };
      tmp_data.translations[translation_obj.index][translation_obj.name] =
        value;
      setData(tmp_data);
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const onInputSelectChange =
    (foreignKey, multiple, d) => (e, item, reason) => {
      e.preventDefault();
      if (multiple) {
        var val = item.map((a) => a.id);
        if (val?.[0] === 0) {
          val = data?.lookups?.[d].map((a) => a.id);
        }
        setData({ ...data, [foreignKey]: val });
      } else {
        setData({ ...data, [foreignKey]: item?.id });
      }
    };

  const onUnlimitedTimeCheckboxChange = () => {
    setIsUnlimitedTime((prev) => !prev);
    const numYears = isUnlimitedTime ? 0 : 200;
    setData({
      ...data,
      end_date: dayjs(new Date())
        .add(numYears, "years")
        .format("YYYY-MM-DDTHH:mm:ss"),
    });
  };

  const getDataById = () => {
    getCouponById(id)
      .then((res) => {
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          if (isNaN(id)) {
            data.translations = getEmptyTranslationArr();
          }
          setData(data);
        }
      })
      .catch((e) => {});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var d = { ...data, action: "upsert" };
    postUpSertCoupon(d)
      .then((res) => {
        setLoading(false);
        const response = res?.data?.success;
        if (response) {
          if (isNaN(id)) {
            navigate("/coupon-details/" + response?.id);
          }
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    mounted.current = true;
    getDataById();
    return () => {
      mounted.current = false;
    };
  }, [id]);

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      {(data?.id || isNaN(id)) && (
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            {"Coupon Details"}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card component="form" noValidate onSubmit={onSubmit}>
                <CardHeader
                  subheader={"The informations can be edited"}
                  title={"Details"}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        label={"Start Date"}
                        type="datetime-local"
                        name="start_date"
                        value={
                          data?.start_date
                            ? dayjs(data?.start_date).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              )
                            : ""
                        }
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        label={"End Date"}
                        type="datetime-local"
                        name="end_date"
                        value={
                          data?.end_date
                            ? dayjs(data?.end_date).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              )
                            : ""
                        }
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        label={"Percentage"}
                        type="number"
                        name="discount_percentage"
                        onChange={handleChange}
                        value={data?.discount_percentage || 0}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        label={"Max number of use"}
                        type="number"
                        name="max_num_of_use"
                        onChange={handleChange}
                        value={data?.max_num_of_use || 0}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      />
                    </Grid>
                    {!isNaN(id) && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label={"Code"}
                          disabled
                          value={data?.code || ""}
                          variant="outlined"
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <FormControlLabel
                        onClick={onUnlimitedTimeCheckboxChange}
                        control={
                          <Checkbox
                            value="remember"
                            checked={isUnlimitedTime}
                            color="primary"
                          />
                        }
                        label={"Unlimited time"}
                      />
                    </Grid>
                    {!isNaN(id) && (
                      <LookupSelectView
                        onInputSelectChange={onInputSelectChange}
                        data={data}
                        d="is_active_lookup"
                        foreignKey="is_active"
                        l={"Is Active"}
                        disabled={isNaN(id)}
                      />
                    )}
                  </Grid>
                </CardContent>
                <Divider />
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                  <ButtonWithLoading
                    loading={loading}
                    success={success}
                    text={"Save"}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default CouponDetails;
