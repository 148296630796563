import React, { useEffect, useState, useRef } from "react";
import "./DataTable.css";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import DataTableToolbar from "./datatable-toolbar/DataTableToolbar";
import dayjs from "dayjs";
import DatatablePagination from "./datatable-pagination/DatatablePagination";

const pageSizeOptions = [
  { value: 15, active: true },
  { value: 50, active: false },
  { value: 100, active: false },
];

const DataTable = ({
  data,
  columns,
  loading,
  onPageChangeProp,
  onRowClick,
  onCreateButtonClick,
  onDeleteDataTableRows,
}) => {
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]?.value);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    startDate: dayjs(new Date()).subtract(10, "years").format("YYYY-MM-DD"),
    endDate: dayjs(new Date()).add(10, "years").format("YYYY-MM-DD"),
    sortBy: { field: "id", sort: "desc" },
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const mounted = useRef(true);
  const inputChangeTimeOut = useRef(null);
  let navigate = useNavigate();

  const onPaginationModelChange = (e, value) => {
    setPage(value);
    try {
      if (window.location.pathname.length > 3) {
        navigate(
          "/" + window.location.pathname.split("/").pop() + "?p=" + value
        );
      }
    } catch (error) {}
  };

  const onPageSizeChange = (value) => {
    setPageSize(value);
    onPaginationModelChange({}, 1);
  };

  const onSearchInputChange = (e) => {
    clearTimeout(inputChangeTimeOut.current);
    inputChangeTimeOut.current = setTimeout(() => {
      onPageChangeProp({
        page,
        pageSize,
        query: e.target.value,
        ...filter,
      });
    }, 1000);
  };

  const onSortModelChange = (e) => {
    setFilter({ ...filter, sortBy: e[0] });
  };

  const onDeleteDialogAgreePress = () => {
    onDeleteDataTableRows({
      page,
      pageSize,
      ...filter,
      id: selectedRows,
      action: "delete",
    });
  };

  const onEditClick = (params) => () => {
    onRowClick(params);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    mounted.current = true;
    if (mounted.current) {
      const p = parseInt(urlParams.get("p"));
      if (!isNaN(p) && p > 0) {
        setPage(p);
      }
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      let tmp_f = { page, pageSize, ...filter };
      onPageChangeProp(tmp_f);
    }
  }, [page, pageSize, filter]);

  const columnHeaderStyle = {
    otherColumns: {
      flex: 1,
    },
    avatarColumn: {
      width: 70,
      minWidth: 70,
      maxWidth: 70,
    },
    firstColumn: {
      width: 50,
      minWidth: 50,
      maxWidth: 50,
    },
  };

  const newCol = [
    {
      field: "index",
      headerName: "#",
      editable: false,
      renderCell: (params) =>
        (page - 1) * pageSize +
        (params.api.getAllRowIds().indexOf(params.id) + 1),
    },
    ...columns,
    {
      field: "action",
      headerName: "-",
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton onClick={onEditClick(params)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ].map((v) =>
    v.field === "id" || v.field === "index" || v.field === "action"
      ? { ...v, ...columnHeaderStyle.firstColumn }
      : v.headerName === "Avatar"
      ? { ...v, ...columnHeaderStyle.avatarColumn }
      : { ...v, ...columnHeaderStyle.otherColumns }
  );

  return (
    <div style={{ width: "100%" }}>
      <DataTableToolbar
        filter_data={filter}
        onSearchInputChange={onSearchInputChange}
        onCreateButtonClick={onCreateButtonClick}
        onSearchByDate={setFilter}
        selectedRows={selectedRows}
        onDeleteDialogAgreePress={onDeleteDialogAgreePress}
      />
      <DataGrid
        columns={newCol || []}
        rows={data?.data || []}
        rowCount={data?.total || 0}
        paginationModel={{ page, pageSize }}
        // onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions.map((item) => item.value)}
        rowHeight={60}
        onSortModelChange={onSortModelChange}
        autoHeight
        paginationMode="server"
        hideFooterPagination={loading}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={setSelectedRows}
        loading={loading}
        slots={{
          pagination: () => (
            <DatatablePagination
              data={data}
              onPageSizeChange={onPageSizeChange}
              pageSizeOptions={pageSizeOptions}
              onPaginationModelChange={onPaginationModelChange}
            />
          ),
        }}
      />
    </div>
  );
};

export default DataTable;
