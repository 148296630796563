import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserById, postAdminUpSertUser } from "../../../core/api/Api";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
} from "@mui/material";
import ButtonWithLoading from "../../_components/button-with-loading/ButtonWithLoading";
import LookupSelectView from "../../_components/input-multi-select/lookup-select-view/LookupSelectView";
import { parseNameOfTranslationInput } from "../../../core/common-service/CommonService";

const UserDetails = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const mounted = useRef(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name?.startsWith("translations")) {
      const translation_obj = parseNameOfTranslationInput(name);
      var tmp_data = { ...data };
      tmp_data.translations[translation_obj.index][translation_obj.name] =
        value;
      setData(tmp_data);
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const onInputSelectChange =
    (foreignKey, multiple, d) => (e, item, reason) => {
      e.preventDefault();
      if (multiple) {
        var val = item.map((a) => a.id);
        if (val?.[0] === 0) {
          val = data?.lookups?.[d].map((a) => a.id);
        }
        setData({ ...data, [foreignKey]: val });
      } else {
        setData({ ...data, [foreignKey]: item?.id });
      }
    };

  const getDataById = () => {
    if (Number(id)) {
      getUserById(id)
        .then((res) => {
          if (mounted.current && res?.data?.success) {
            const data = res.data.success;
            setData(data);
          }
        })
        .catch((e) => {});
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var d = { ...data, action: "upsert" };
    postAdminUpSertUser(d)
      .then((res) => {
        setLoading(false);
        const response = res?.data?.success;
        if (response) {
          if (isNaN(id)) {
            navigate("/user-details/" + response?.id);
          }
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    mounted.current = true;
    getDataById();
    return () => {
      mounted.current = false;
    };
  }, [id]);

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      {(data?.id || isNaN(id)) && (
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            {"User Details"}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card component="form" noValidate onSubmit={onSubmit}>
                <CardHeader
                  subheader={"The informations can be edited"}
                  title={"Details"}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        disabled={!isNaN(id)}
                        fullWidth
                        label={"Email Address"}
                        name="email"
                        onChange={handleChange}
                        value={data?.email || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={"City"}
                        name="city"
                        onChange={handleChange}
                        required
                        value={data?.city || ""}
                        variant="outlined"
                      />
                    </Grid>
                    {isNaN(id) && (
                      <>
                        <Grid item md={6} xs={12}>
                          <TextField
                            disabled={!isNaN(id)}
                            fullWidth
                            required
                            type="password"
                            label={"Password"}
                            name="password"
                            onChange={handleChange}
                            value={data?.password}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            disabled={!isNaN(id)}
                            fullWidth
                            type="password"
                            required
                            label={"Confirm Password"}
                            name="confirm_password"
                            onChange={handleChange}
                            value={data?.confirm_password}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                    {!isNaN(id) && (
                      <LookupSelectView
                        onInputSelectChange={onInputSelectChange}
                        data={data}
                        d="is_active_lookup"
                        foreignKey="is_active"
                        l={"Is Active"}
                        disabled={isNaN(id)}
                      />
                    )}
                  </Grid>
                </CardContent>
                <Divider />
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                  <ButtonWithLoading
                    loading={loading}
                    success={success}
                    text={"Save"}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default UserDetails;
