import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import { Route, BrowserRouter, Routes, useNavigate } from "react-router-dom";
import ThemeCustomization from "./core/theme-customization/ThemeCustomization";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ViewContainer from "./core/routes/view-container/ViewContainer";
import {
  getCookie,
  deleteCookie,
  setCookie,
} from "./core/common-service/CommonService";
import { setDefaultHeader, postRefreshToken } from "./core/api/Api";
import { StoreContext } from "./core/context/StoreContext";
import { RouteContext } from "./core/context/RouteContext";
import dayjs from "dayjs";
import RequestErrorMessage from "./components/_components/request-error-message/RequestErrorMessage";
import ProtectedRoute from "./core/routes/protected_route/ProtectedRoute";
import ComingSoon from "./components/_components/coming-soon/ComingSoon";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const PreLoader = () => {
  const { setIsLoggedIn, setUser, setSnackbarMessage } =
    useContext(StoreContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const checkToken = () => {
    const token = sessionStorage.getItem("token") || getCookie("token");

    if (token) {
      setDefaultHeader("Bearer " + token);
      postRefreshToken()
        .then((res) => {
          const response = res.data;
          if (response?.success) {
            const token = response?.success?.token;
            const user = response?.success?.user;
            if (user?.user_role_id === 1) {
              if (user?.is_verified) {
                setIsLoggedIn(true);
                setUser(user);
                setCookie("token", token);
              } else {
                setSnackbarMessage((prevState) => ({
                  message: "User not verified",
                  open: true,
                  severity: "error",
                }));
              }
            } else {
              setSnackbarMessage((prevState) => ({
                message: "User not allowed to login",
                open: true,
                severity: "error",
              }));
            }
          } else {
            setIsLoggedIn(false);
            sessionStorage.clear();
            deleteCookie("token");
            navigate("/login");
          }
          setOpen(false);
        })
        .catch((e) => {
          setOpen(false);
          setIsLoggedIn(false);
          sessionStorage.clear();
          deleteCookie("token");
          navigate("/login");
        });
    } else {
      setOpen(false);
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkToken();
    return () => {};
  }, []);

  return (
    <Backdrop
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 10,
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const App = () => {
  const { paths } = useContext(RouteContext);

  return (
    <BrowserRouter>
      <RequestErrorMessage />
      <PreLoader />
      <ThemeCustomization>
        <ViewContainer>
          <Routes>
            {paths.map((item, index) =>
              item.path.map((el) => (
                <Route
                  key={index}
                  path={el + item.params.join("")}
                  element={
                    item.protected ? (
                      <ProtectedRoute>{item.component}</ProtectedRoute>
                    ) : (
                      item.component
                    )
                  }
                />
              ))
            )}
            <Route path="*" element={<ComingSoon />} />
          </Routes>
        </ViewContainer>
      </ThemeCustomization>
    </BrowserRouter>
  );
};

export default App;
