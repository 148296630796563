import { languages } from "../constants/constants";

export function setCookie(cname, cvalue) {
  const exdays = 30;
  var secure = "";
  if (window.location.protocol == "https") {
    secure = "secure;";
  }
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + secure + expires + "; path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function deleteCookie(cname) {
  document.cookie =
    cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";
}

export function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
}

export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  return browserLanguage?.split("-")[0];
};

export function removeWhiteSpaceFromString(text) {
  return text?.replace(/\s/g, "");
}

export const fullNameValidation = (value) => {
  // check if there is numbers
  if (/\d/.test(value)) {
    return false;
  } else {
    if (value.includes("  ")) {
      value = value.trimEnd();
    }
    if (value.trimEnd().split(" ").length > 1) {
      return true || value.match(/^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/) ? true : false;
    } else {
      return false || value.match(/^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/) ? true : false;
    }
  }
};

export const emailValidation = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const checkFormErrors = (formErrors) => {
  return Object.values(formErrors).every((item) => item == true);
};

export const getEmptyTranslationArr = (
  properties = [
    { key: "name", value: "" },
    { key: "description", value: "" },
  ]
) => {
  return languages.map((language) => {
    let translationObj = { locale: language.code };
    properties.forEach((property) => {
      translationObj[property.key] = property.value;
    });
    return translationObj;
  });
};

export const parseNameOfTranslationInput = (str) => {
  const matches = str.match(/translations-(\d+)-(\w+)/);
  if (matches) {
    const index = matches[1];
    const name = matches[2];
    return { index, name };
  }
  return { index: 0, name: "" };
};
