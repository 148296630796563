import axios from "axios";
import { getBrowserLanguage, getCookie } from "../common-service/CommonService";

export const BASE_URL = window.location.host.startsWith("localhost")
  ? "http://localhost:8000/"
  : window.location.host.startsWith("dev-")
  ? "https://dev-api.talegacyremembered.com/"
  : "https://api.talegacyremembered.com/";
export const MEDIA_URL = BASE_URL.slice(0, -1);

axios.defaults.baseURL = BASE_URL + "api/";
axios.defaults.timeout = 3600 * 1000;
axios.defaults.headers.common["lng"] =
  getCookie("lng") ?? getBrowserLanguage() ?? "en";

export function setDefaultHeader(value) {
  axios.defaults.headers.common["Authorization"] = value;
}

// Auth
export function postRefreshToken() {
  return axios.post("refreshToken");
}
export function postRevokeToken() {
  return axios.post("revokeToken");
}
export function postLogin(data) {
  return axios.post("login", data);
}
export function postRegister(data) {
  return axios.post("register", data);
}

// Services
export function getServiceById(id) {
  return axios.get("getServiceById/" + id);
}
export function postGetAllServices(data) {
  return axios.post("postGetAllServices", data);
}
export function postUpSertService(data) {
  return axios.post("postUpSertService", data);
}

// General Categories
export function getGeneralCategoryById(id) {
  return axios.get("getGeneralCategoryById/" + id);
}
export function postGetAllGeneralCategories(data) {
  return axios.post("postGetAllGeneralCategories", data);
}
export function postUpSertGeneralCategory(data) {
  return axios.post("postUpSertGeneralCategory", data);
}

// Survey Questions
export function getSurveyQuestionById(id) {
  return axios.get("getSurveyQuestionById/" + id);
}
export function postGetAllSurveyQuestions(data) {
  return axios.post("postGetAllSurveyQuestions", data);
}
export function postUpSertSurveyQuestion(data) {
  return axios.post("postUpSertSurveyQuestion", data);
}

// Survey Answers
export function getSurveyAnswerById(id) {
  return axios.get("getSurveyAnswerById/" + id);
}
export function postGetAllSurveyAnswers(data) {
  return axios.post("postGetAllSurveyAnswers", data);
}
export function postUpSertSurveyAnswer(data) {
  return axios.post("postUpSertSurveyAnswer", data);
}

// Plans
export function getPlanById(id) {
  return axios.get("getPlanById/" + id);
}
export function postGetAllPlans(data) {
  return axios.post("postGetAllPlans", data);
}
export function postUpSertPlan(data) {
  return axios.post("postUpSertPlan", data);
}

// Plans
export function getPlanOptionById(id) {
  return axios.get("getPlanOptionById/" + id);
}
export function postGetAllPlanOptions(data) {
  return axios.post("postGetAllPlanOptions", data);
}
export function postUpSertPlanOption(data) {
  return axios.post("postUpSertPlanOption", data);
}

// Coupons
export function getCouponById(id) {
  return axios.get("getCouponById/" + id);
}
export function postGetAllCoupons(data) {
  return axios.post("postGetAllCoupons", data);
}
export function postUpSertCoupon(data) {
  return axios.post("postUpSertCoupon", data);
}

// Users
export function getUserById(id) {
  return axios.get("getUserById/" + id);
}
export function postGetAllUsers(data) {
  return axios.post("postGetAllUsers", data);
}
export function postAdminUpSertUser(data) {
  return axios.post("postAdminUpSertUser", data);
}

// Question Categories
export function getQuestionCategoryById(id) {
  return axios.get("getQuestionCategoryById/" + id);
}
export function postGetAllQuestionCategories(data) {
  return axios.post("postGetAllQuestionCategories", data);
}
export function postUpSertQuestionCategory(data) {
  return axios.post("postUpSertQuestionCategory", data);
}

// Questions
export function getQuestionById(id) {
  return axios.get("getQuestionById/" + id);
}
export function postGetAllQuestions(data) {
  return axios.post("postGetAllQuestions", data);
}
export function postUpSertQuestion(data) {
  return axios.post("postUpSertQuestion", data);
}

// Category Based Type
export function getCategoryBasedTypeById(id) {
  return axios.get("getCategoryBasedTypeById/" + id);
}
export function postGetAllCategoryBasedTypes(data) {
  return axios.post("postGetAllCategoryBasedTypes", data);
}
export function postUpSertCategoryBasedType(data) {
  return axios.post("postUpSertCategoryBasedType", data);
}

// Category Length Type
export function getCategoryLengthTypeById(id) {
  return axios.get("getCategoryLengthTypeById/" + id);
}
export function postGetAllCategoryLengthTypes(data) {
  return axios.post("postGetAllCategoryLengthTypes", data);
}
export function postUpSertCategoryLengthType(data) {
  return axios.post("postUpSertCategoryLengthType", data);
}
