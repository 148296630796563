import React, { useEffect, useRef, useState } from "react";
import {
  postGetAllSurveyAnswers,
  postUpSertSurveyAnswer,
} from "../../../../core/api/Api";
import DataTable from "../../../_components/datatable/DataTable";
import { useNavigate, useParams } from "react-router-dom";

const SurveyAnswers = () => {
  let { survey_question_id } = useParams();
  const mounted = useRef(true);
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "description", headerName: "Description" },
  ];

  const getData = (data) => {
    setLoading(true);
    data.survey_question_id = survey_question_id;
    postGetAllSurveyAnswers(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onDeleteDataTableRows = (data) => {
    setLoading(true);
    data.survey_question_id = survey_question_id;
    postUpSertSurveyAnswer(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onRowClick = (e) => {
    navigate(
      "/survey-answer-details/" + e.id + "/question/" + survey_question_id
    );
  };

  const onCreateButtonClick = () => {
    navigate("/survey-answer-details/create/question/" + survey_question_id);
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={data}
      loading={loading}
      onPageChangeProp={getData}
      onRowClick={onRowClick}
      onCreateButtonClick={onCreateButtonClick}
      onDeleteDataTableRows={onDeleteDataTableRows}
    />
  );
};

export default SurveyAnswers;
