import React, { useState } from "react";
import { Grid, Box, TextField, Button, DialogContentText } from "@mui/material";
import useDialog from "../../../_hooks/dialog-hook/useDialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const DataTableToolbar = ({
  onSearchInputChange,
  onSearchByDate,
  onCreateButtonClick,
  filter_data,
  selectedRows,
  onDeleteDialogAgreePress,
}) => {
  const [filter, setFilter] = useState(filter_data);

  const [DeleteDialogPopup, openDeleteDialog, closeDeleteDialog] = useDialog({
    title: "Awaiting deleteing records...",
  });

  const onStartDateChange = (value) => {
    if (new Date(filter.endDate) > new Date(value)) {
      setFilter({ ...filter, startDate: value });
    }
  };
  const onEndDateChange = (value) => {
    if (new Date(value) > new Date(filter.startDate)) {
      setFilter({ ...filter, endDate: value });
    }
  };

  const _onSearchByDate = () => {
    onSearchByDate(filter);
  };

  const _onDeleteDialogAgreePress = () => {
    closeDeleteDialog();
    onDeleteDialogAgreePress();
  };

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      {/* <Grid item lg={2} xs={12}>
        <TextField
          onChange={onSearchInputChange}
          label={"Search"}
          variant="standard"
        />
      </Grid>
      <Grid item lg={8} xs={12} sx={{ display: "flex" }}>
        <DatePicker
          label={"Start Date"}
          value={dayjs(new Date(filter.startDate))}
          onChange={onStartDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <Box sx={{ mx: 1 }}>
          <DatePicker
            label={"End Date"}
            value={dayjs(new Date(filter.endDate))}
            onChange={onEndDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Button onClick={_onSearchByDate} variant="contained">
          {"Search"}
        </Button>
      </Grid> */}
      <Grid
        item
        lg={2}
        xs={12}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          sx={{ mr: 1 }}
          disabled={selectedRows?.length === 0}
          onClick={openDeleteDialog}
          color="error"
          variant="contained"
        >
          {"Delete"}
        </Button>
        <Button
          onClick={onCreateButtonClick}
          color="success"
          variant="contained"
        >
          {"Create"}
        </Button>
      </Grid>
      <DeleteDialogPopup
        children={
          <DialogContentText>
            {"Are you sure to delete these records?"}
          </DialogContentText>
        }
        actions={
          <>
            <Button onClick={closeDeleteDialog}>{"Disagree"}</Button>
            <Button onClick={_onDeleteDialogAgreePress} autoFocus>
              {"Agree"}
            </Button>
          </>
        }
      />
    </Grid>
  );
};
export default DataTableToolbar;
