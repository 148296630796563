import React, { useState, useContext } from "react";
import {
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import images from "../../core/constants/images";
import { postLogin, setDefaultHeader } from "../../core/api/Api";
import { setCookie } from "../../core/common-service/CommonService";
import { StoreContext } from "../../core/context/StoreContext";
import ButtonWithLoading from "../_components/button-with-loading/ButtonWithLoading";
import { useNavigate, useLocation } from "react-router-dom";

const Login = () => {
  const { setIsLoggedIn, setUser, setSnackbarMessage } =
    useContext(StoreContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onRememberMeChange = () => {
    setRememberMe((prop) => !prop);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    postLogin({ email, password })
      .then((res) => {
        setLoading(false);
        const status_code = res?.status;
        const response = res?.data?.success;
        if (status_code === 200) {
          const token = response?.token;
          const user = response?.user;
          if (user?.user_role_id === 1) {
            if (user?.is_verified) {
              setDefaultHeader("Bearer " + token);
              setIsLoggedIn(true);
              setUser(user);
              setTimeout(() => {
                if (rememberMe) {
                  setCookie("token", token);
                } else {
                  sessionStorage.setItem("token", token);
                }
                const from = location.state?.from || "/"; // default to home page if no previous location is found
                navigate(from, { state: location.state });
              }, 300);
            } else {
              setSnackbarMessage((prevState) => ({
                message: "User not allowed",
                open: true,
                severity: "error",
              }));
            }
          } else {
            setSnackbarMessage((prevState) => ({
              message: "User not allowed to login",
              open: true,
              severity: "error",
            }));
          }
        }
      })
      .catch((e) => {
        const response = e?.response;
        const status_code = response?.status;
        if (status_code === 400) {
          navigate("/confirm-registration", { state: { email } });
        }
        const error = response?.data?.error ?? "Invalid Request";
        setSnackbarMessage((prevState) => ({
          message: error,
          open: true,
          severity: "error",
        }));
        try {
          let first_error = Object.values(error)[0] ?? "Invalid Request";
          if (Array.isArray(first_error)) {
            first_error = first_error[0] ?? "Invalid Request";
          } else {
            first_error = error;
          }
          setSnackbarMessage((prevState) => ({
            message: first_error ?? "Invalid Request",
            open: true,
            severity: "error",
          }));
        } catch (error) {
          setSnackbarMessage((prevState) => ({
            message: "Error! Invalid Request",
            open: true,
            severity: "error",
          }));
        }
        setLoading(false);
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${images.logo})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode == "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {"Sign in"}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              onChange={onEmailChange}
              margin="normal"
              required
              fullWidth
              label={"Email Address"}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={onPasswordChange}
              margin="normal"
              required
              fullWidth
              name="password"
              label={"Password"}
              type="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              onClick={onRememberMeChange}
              control={
                <Checkbox
                  value="remember"
                  checked={rememberMe}
                  color="primary"
                />
              }
              label={"Remember me"}
            />

            <Box sx={{ mt: 1, position: "relative" }}>
              <ButtonWithLoading
                // success={success}
                loading={loading}
                text={"Sign in"}
              />
            </Box>
            <Typography
              sx={{ mt: 5 }}
              variant="body2"
              color="text.secondary"
              align="center"
            >
              {"Copyright ©"}
              <Link color="inherit" href="https://talegacyremembered.com/">
                talegacyremembered.com
              </Link>{" "}
              {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
