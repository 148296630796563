import React, { Suspense } from "react";
import { Backdrop, CssBaseline, CircularProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const ThemeCustomization = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // primary: {
          //   main: "#ffd433",
          // },
          mode: prefersDarkMode ? "dark" : "light",
        },
        direction: "ltr",
      }),
    [prefersDarkMode]
  );
  return (
    <Suspense
      fallback={
        <Backdrop
          open={true}
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 10,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </LocalizationProvider>
    </Suspense>
  );
};

export default ThemeCustomization;
