import React, { createContext, useState } from "react";
import {
  AnalyticsOutlined as AnalyticsOutlinedIcon,
  Group as GroupIcon,
  FormatListBulletedOutlined as FormatListBulletedOutlinedIcon,
  Category as CategoryIcon,
  ElectricalServices as ElectricalServicesIcon,
  Ballot as BallotIcon,
  Poll as PollIcon,
  TableChart as TableChartIcon,
  Quiz as QuizIcon,
  DynamicForm as DynamicFormIcon,
  Discount as DiscountIcon,
} from "@mui/icons-material";
import Login from "../../components/login/Login";
import Users from "../../components/users/Users";
import Reports from "../../components/reports/Reports";
import UserDetails from "../../components/users/user-details/UserDetails";
import CategoryBasedTypes from "../../components/lookups/category-based-types/CategoryBasedTypes";
import CategoryLengthTypes from "../../components/lookups/category-length-types/CategoryLengthTypes";
import CategoryBasedTypeDetails from "../../components/lookups/category-based-types/category-based-type-details/CategoryBasedTypeDetails";
import CategoryLengthTypeDetails from "../../components/lookups/category-length-types/category-length-type-details/CategoryLengthTypeDetails";
import Services from "../../components/services/Services";
import ServiceDetails from "../../components/services/service-details/ServiceDetails";
import GeneralCategories from "../../components/general-categories/GeneralCategories";
import GeneralCategoryDetails from "../../components/general-categories/general-category-details/GeneralCategoryDetails";
import SurveyQuestions from "../../components/lookups/surveys/survey-questions/SurveyQuestions";
import SurveyQuestionDetails from "../../components/lookups/surveys/survey-questions/survey-question-details/SurveyQuestionDetails";
import SurveyAnswers from "../../components/lookups/surveys/survey-answers/SurveyAnswers";
import SurveyAnswerDetails from "../../components/lookups/surveys/survey-answers/survey-answer-details/SurveyAnswerDetails";
import Plans from "../../components/lookups/plans/plans/Plans";
import PlanDetails from "../../components/lookups/plans/plans/plan-details/PlanDetails";
import PlanOptions from "../../components/lookups/plans/plan-options/PlanOptions";
import PlanOptionDetails from "../../components/lookups/plans/plan-options/plan-options-details/PlanOptionDetails";
import Questions from "../../components/questions/Questions";
import QuestionDetails from "../../components/questions/question-details/QuestionDetails";
import QuestionCategories from "../../components/question-categories/QuestionCategories";
import QuestionCategoryDetails from "../../components/question-categories/question-category-details/QuestionCategoryDetails";
import Coupons from "../../components/lookups/coupons/Coupons";
import CouponDetails from "../../components/lookups/coupons/coupon-details/CouponDetails";

export const RouteContext = createContext();

const RouteProvider = ({ children }) => {
  const [paths, setPaths] = useState([
    {
      protected: false,
      path: ["/login"],
      params: [],
      component: <Login />,
    },
    {
      protected: true,
      path: ["/", "/reports"],
      params: [],
      component: <Reports />,
    },
    {
      protected: true,
      path: ["/services"],
      params: [],
      component: <Services />,
    },
    {
      protected: true,
      path: ["/service-details"],
      params: ["/:id"],
      component: <ServiceDetails />,
    },
    {
      protected: true,
      path: ["/general-categories"],
      params: [],
      component: <GeneralCategories />,
    },
    {
      protected: true,
      path: ["/general-category-details"],
      params: ["/:id"],
      component: <GeneralCategoryDetails />,
    },
    {
      protected: true,
      path: ["/users"],
      params: [],
      component: <Users />,
    },
    {
      protected: true,
      path: ["/user-details"],
      params: ["/:id"],
      component: <UserDetails />,
    },
    {
      protected: true,
      path: ["/questions"],
      params: [],
      component: <Questions />,
    },
    {
      protected: true,
      path: ["/question-details"],
      params: ["/:id"],
      component: <QuestionDetails />,
    },
    {
      protected: true,
      path: ["/question-categories"],
      params: [],
      component: <QuestionCategories />,
    },
    {
      protected: true,
      path: ["/question-category-details"],
      params: ["/:id"],
      component: <QuestionCategoryDetails />,
    },
    {
      protected: true,
      path: ["/survey-questions"],
      params: [],
      component: <SurveyQuestions />,
    },
    {
      protected: true,
      path: ["/survey-question-details"],
      params: ["/:id"],
      component: <SurveyQuestionDetails />,
    },
    {
      protected: true,
      path: ["/survey-answers"],
      params: ["/:survey_question_id"],
      component: <SurveyAnswers />,
    },
    {
      protected: true,
      path: ["/survey-answer-details"],
      params: ["/:id", "/question/:survey_question_id"],
      component: <SurveyAnswerDetails />,
    },
    {
      protected: true,
      path: ["/coupons"],
      params: [],
      component: <Coupons />,
    },
    {
      protected: true,
      path: ["/coupon-details"],
      params: ["/:id"],
      component: <CouponDetails />,
    },
    {
      protected: true,
      path: ["/plans"],
      params: [],
      component: <Plans />,
    },
    {
      protected: true,
      path: ["/plan-details"],
      params: ["/:id"],
      component: <PlanDetails />,
    },
    {
      protected: true,
      path: ["/plan-options"],
      params: ["/:plan_id"],
      component: <PlanOptions />,
    },
    {
      protected: true,
      path: ["/plan-option-details"],
      params: ["/:id", "/plan/:plan_id"],
      component: <PlanOptionDetails />,
    },
    {
      protected: true,
      path: ["/category-based-types"],
      params: [],
      component: <CategoryBasedTypes />,
    },
    {
      protected: true,
      path: ["/category-based-type-details"],
      params: ["/:id"],
      component: <CategoryBasedTypeDetails />,
    },
    {
      protected: true,
      path: ["/category-length-types"],
      params: [],
      component: <CategoryLengthTypes />,
    },
    {
      protected: true,
      path: ["/category-length-type-details"],
      params: ["/:id"],
      component: <CategoryLengthTypeDetails />,
    },
  ]);
  const [navLinks, setNavLinks] = useState([
    {
      title: "Reports",
      icon: <AnalyticsOutlinedIcon />,
      path: "/reports",
      active: false,
    },
    {
      title: "Services",
      icon: <ElectricalServicesIcon />,
      path: "/services",
      active: false,
    },
    {
      title: "General Categories",
      icon: <BallotIcon />,
      path: "/general-categories",
      active: false,
    },
    {
      title: "Question Categories",
      icon: <DynamicFormIcon />,
      path: "/question-categories",
      active: false,
    },
    {
      title: "Questions",
      icon: <QuizIcon />,
      path: "/questions",
      active: false,
    },
    {
      title: "Users",
      icon: <GroupIcon />,
      path: "/users",
      active: false,
    },
    {
      title: "Lookups",
      icon: <FormatListBulletedOutlinedIcon />,
      active: false,
      collapse: [
        {
          title: "Survey Questions",
          icon: <PollIcon />,
          path: "/survey-questions",
          active: false,
        },
        {
          title: "Plans",
          icon: <TableChartIcon />,
          path: "/plans",
          active: false,
        },
        {
          title: "Coupons",
          icon: <DiscountIcon />,
          path: "/coupons",
          active: false,
        },
        {
          title: "Category Based Type",
          icon: <CategoryIcon />,
          path: "/category-based-types",
          active: false,
        },
        {
          title: "Category Length Type",
          icon: <CategoryIcon />,
          path: "/category-length-types",
          active: false,
        },
      ],
    },
  ]);

  return (
    <RouteContext.Provider
      value={{
        paths,
        setPaths,
        navLinks,
        setNavLinks,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

export default RouteProvider;
