import React, { useEffect, useRef, useState } from "react";
import { postGetAllServices, postUpSertService } from "../../core/api/Api";
import DataTable from "../_components/datatable/DataTable";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const mounted = useRef(true);
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "description", headerName: "Description" },
  ];

  const getData = (data) => {
    setLoading(true);
    postGetAllServices(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onDeleteDataTableRows = (data) => {
    setLoading(true);
    postUpSertService(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onRowClick = (e) => {
    navigate("/service-details/" + e.id);
  };

  const onCreateButtonClick = () => {
    navigate("/service-details/create");
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={data}
      loading={loading}
      onPageChangeProp={getData}
      onRowClick={onRowClick}
      onCreateButtonClick={onCreateButtonClick}
      onDeleteDataTableRows={onDeleteDataTableRows}
    />
  );
};

export default Services;
