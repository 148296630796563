import React, { useState } from "react";
import { Chip, Pagination, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";

const DatatablePagination = ({
  onPageSizeChange,
  pageSizeOptions,
  onPaginationModelChange,
  data,
}) => {
  const [pageSizeOptionsData, setpageSizeOptionsData] =
    useState(pageSizeOptions);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (index) => (e) => {
    e.preventDefault();
    setAnchorEl(null);
    var tmpData = [...pageSizeOptionsData];
    pageSizeOptionsData.forEach((element, i) => {
      element.active = Number(i) === Number(index);
    });
    setpageSizeOptionsData(tmpData);
    onPageSizeChange(pageSizeOptionsData[index].value);
  };

  const getActiveMenuItem = () => {
    return pageSizeOptionsData.find((el) => el.active)?.value;
  };

  return (
    <div style={{ display: "flex" }}>
      <Chip
        icon={<KeyboardArrowDownIcon />}
        label={getActiveMenuItem()}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {pageSizeOptionsData.map((item, index) => (
          <MenuItem key={index} onClick={onClick(index)}>
            {item.value}
          </MenuItem>
        ))}
      </Menu>
      <Pagination
        shape="rounded"
        showFirstButton
        showLastButton
        count={data?.last_page}
        page={data?.current_page}
        onChange={onPaginationModelChange}
      />
    </div>
  );
};

export default DatatablePagination;
