import React from "react";
import { Grid, Typography } from "@mui/material";

const ComingSoon = () => {
  return (
    <Grid
      container
      display="flex"
      minHeight="80vh"
      minWidth="100%"
      spacing={0}
      alignItems="center"
      justifyContent="center"
    >
      <Typography>Coming Soon</Typography>
    </Grid>
  );
};
export default ComingSoon;
