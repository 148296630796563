import React, { useEffect, useRef, useState } from "react";
import {
  postGetAllCategoryLengthTypes,
  postUpSertCategoryLengthType,
} from "../../../core/api/Api";
import DataTable from "../../_components/datatable/DataTable";
import { useNavigate } from "react-router-dom";

const CategoryLengthTypes = () => {
  const mounted = useRef(true);
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "description", headerName: "Description" },
    { field: "min_num_pages", headerName: "Min Pages" },
    { field: "max_num_pages", headerName: "Max Pages" },
    { field: "min_num_chapters", headerName: "Min Num. Chapters" },
    { field: "max_num_chapters", headerName: "Max Num. Chapters" },
    {
      field: "general_category",
      headerName: "General Category",
      renderCell: (params) => params?.value?.name ?? "",
    },
  ];

  const getData = (data) => {
    setLoading(true);
    postGetAllCategoryLengthTypes(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onDeleteDataTableRows = (data) => {
    setLoading(true);
    postUpSertCategoryLengthType(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onRowClick = (e) => {
    navigate("/category-length-type-details/" + e.id);
  };

  const onCreateButtonClick = () => {
    navigate("/category-length-type-details/create");
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={data}
      loading={loading}
      onPageChangeProp={getData}
      onRowClick={onRowClick}
      onCreateButtonClick={onCreateButtonClick}
      onDeleteDataTableRows={onDeleteDataTableRows}
    />
  );
};

export default CategoryLengthTypes;
