import React, { useEffect, useRef, useState } from "react";
import DataTable from "../_components/datatable/DataTable";
import { useNavigate } from "react-router-dom";
import {
  postGetAllGeneralCategories,
  postUpSertGeneralCategory,
} from "../../core/api/Api";

const GeneralCategories = () => {
  const mounted = useRef(true);
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "description", headerName: "Description" },
    {
      field: "service",
      headerName: "Service",
      renderCell: (params) => params?.value?.name ?? "",
    },
  ];

  const getData = (data) => {
    setLoading(true);
    postGetAllGeneralCategories(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onDeleteDataTableRows = (data) => {
    setLoading(true);
    postUpSertGeneralCategory(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onRowClick = (e) => {
    navigate("/general-category-details/" + e.id);
  };

  const onCreateButtonClick = () => {
    navigate("/general-category-details/create");
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={data}
      loading={loading}
      onPageChangeProp={getData}
      onRowClick={onRowClick}
      onCreateButtonClick={onCreateButtonClick}
      onDeleteDataTableRows={onDeleteDataTableRows}
    />
  );
};

export default GeneralCategories;
