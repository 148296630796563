import React, { useEffect, useRef, useState } from "react";
import { postGetAllCoupons, postUpSertCoupon } from "../../../core/api/Api";
import DataTable from "../../_components/datatable/DataTable";
import { useNavigate } from "react-router-dom";

const Coupons = () => {
  const mounted = useRef(true);
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "created_by_email", headerName: "Created By" },
    { field: "code", headerName: "Code" },
    { field: "discount_percentage", headerName: "Percentage" },
    { field: "max_num_of_use", headerName: "Max num of use" },
    { field: "start_date", headerName: "Start Date" },
    { field: "end_date", headerName: "End Date" },
    { field: "service_sessions_count", headerName: "Used" },
  ];

  const getData = (data) => {
    setLoading(true);
    postGetAllCoupons(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onDeleteDataTableRows = (data) => {
    setLoading(true);
    postUpSertCoupon(data)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setData(data);
        }
      })
      .catch((e) => {
        if (mounted.current) setLoading(false);
      });
  };

  const onRowClick = (e) => {
    navigate("/coupon-details/" + e.id);
  };

  const onCreateButtonClick = () => {
    navigate("/coupon-details/create");
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={data}
      loading={loading}
      onPageChangeProp={getData}
      onRowClick={onRowClick}
      onCreateButtonClick={onCreateButtonClick}
      onDeleteDataTableRows={onDeleteDataTableRows}
    />
  );
};

export default Coupons;
