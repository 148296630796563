import React, { useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Snackbar, Alert, AlertTitle } from "@mui/material";
import { StoreContext } from "../../../core/context/StoreContext";
import { deleteCookie } from "../../../core/common-service/CommonService";
import { useNavigate } from "react-router-dom";

const RequestErrorMessage = () => {
  const { snackbarMessage, setIsLoggedIn, setSnackbarMessage } =
    useContext(StoreContext);
  let navigate = useNavigate();
  const mounted = useRef(true);
  const info_message_timeout = useRef(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage();
    setSnackbarMessage((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  useEffect(() => {
    mounted.current = true;
    if (snackbarMessage.open && mounted.current) {
      info_message_timeout.current = setTimeout(() => {
        if (snackbarMessage.open && mounted.current) {
          setSnackbarMessage((prevState) => ({
            ...prevState,
            open: false,
          }));
        }
      }, 3000);
    }
    return () => {
      clearTimeout(info_message_timeout.current);
      mounted.current = false;
    };
  }, [snackbarMessage.open]);

  useEffect(() => {
    axios.interceptors.response.use(
      (res) => {
        try {
          if (res?.request?.responseURL) {
            if (res?.request?.responseURL.includes("UpSert")) {
              setSnackbarMessage((prevState) => ({
                message: "Saved!",
                open: true,
                severity: "success",
              }));
            }
          }
        } catch (error) {
          setSnackbarMessage((prevState) => ({
            ...prevState,
            open: false,
          }));
        }
        return res;
      },
      (error) => {
        try {
          try {
            var response = error.response.data.error;
            const status_code = error.response.status;
            if (status_code === 401) {
              deleteCookie("token");
              sessionStorage.removeItem("token");
              setIsLoggedIn(false);
              navigate("/");
            }
          } catch (error) {
            response = "Error in your request";
          }
          setSnackbarMessage((prevState) => ({
            message: response,
            open: true,
            severity: "error",
          }));
        } catch (error) {
          setSnackbarMessage((prevState) => ({
            message: "",
            open: false,
            severity: "error",
          }));
        }
      }
    );
  }, []);

  return (
    <Snackbar
      open={snackbarMessage.open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        severity={snackbarMessage.severity}
        onClose={handleClose}
        // icon={
        //   severity === "success" ? (
        //     <CheckIcon fontSize="inherit" />
        //   ) : (
        //     <ErrorOutlineIcon fontSize="inherit" />
        //   )
        // }
      >
        <AlertTitle>
          {snackbarMessage.severity === "success" ? "Success" : "Error"}
        </AlertTitle>
        {snackbarMessage.message}
      </Alert>
    </Snackbar>
  );
};

export default RequestErrorMessage;
