import React, { createContext, useState } from "react";

export const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [user, setUser] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: "",
    open: false,
    severity: "error",
  });

  return (
    <StoreContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        snackbarMessage,
        setSnackbarMessage,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
